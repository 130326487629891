import React from "react"
import Link from "../components/link"
import Tile from "../components/tile"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = ({ transitionStatus }) => (
  <Layout transitionStatus={transitionStatus} buy={false}>
    <SEO title="Page two" />
    <div className="buy text-xl" style={{ margin: "20vh -3rem 20vh" }}>
      <span className="buy-tr">buy&nbsp;IMages&nbsp;buy&nbsp;IMages&nbsp;</span>
    </div>
    <div className="grid15">
      <div
        className="underline"
        style={{
          gridColumn: "5 / 8",
          gridRow: "1",
        }}
      >
        Non-Commercial
      </div>
      <div
        style={{
          gridColumn: "9 / 12",
          gridRow: "1",
        }}
      >
        <p>
          If you like patterbrutalist project and want to support it, you can
          buy our illustrations and letters for personal use.
        </p>

        <p>
          You can use them for exmaple to print on t-shirt or bring a poster to
          your room wall. no commercial usage allowed by this type of license.
        </p>

        <p>
          <br />
          <Link to="https://gum.co/pbVDNKh" className="rounded">
            buy now
          </Link>
        </p>
      </div>
      <div
        className="underline"
        style={{
          gridColumn: "9 / 12",
          gridRow: "5",
        }}
      >
        what’s inside
      </div>
      <div
        style={{
          gridColumn: "5 / 8",
          gridRow: "7",
        }}
      >
        1
      </div>
      <div
        style={{
          gridColumn: "9 / 12",
          gridRow: "7",
        }}
      >
        <p>4 × patternbrutalist issue 02 illustrations</p>

        <p>from 1920×1080 to 2560×1440px</p>

        <p>tileable (can be used as patterns)</p>
      </div>
      <div
        style={{
          gridColumn: "1 / 4",
          gridRow: "11",
        }}
      >
        <Tile id={2} />
      </div>
      <div
        style={{
          gridColumn: "5 / 8",
          gridRow: "11",
        }}
      >
        <Tile id={1} />
      </div>
      <div
        style={{
          gridColumn: "9 / 12",
          gridRow: "11",
        }}
      >
        <Tile id={3} />
      </div>
      <div
        style={{
          gridColumn: "13 / 16",
          gridRow: "11",
        }}
      >
        <Tile id={4} />
      </div>
      <div
        style={{
          gridColumn: "5 / 8",
          gridRow: "15",
        }}
      >
        2
      </div>
      <div
        style={{
          gridColumn: "9 / 12",
          gridRow: "15",
        }}
      >
        <p>patternbrutalist font</p>

        <p>monospaced, latin basic symbols + few alternatives</p>

        <p>desctop (ttf) + web (woff)</p>
      </div>
      <div
        className="text-l"
        style={{
          gridColumn: "3 / 14",
          gridRow: "19",
        }}
      >
        pattern
        <br />
        &nbsp;&nbsp;brutalIst Monospased
        <br />
        &nbsp;&nbsp;&nbsp;.ttf .woff
      </div>
      <div
        className="underline"
        style={{
          gridColumn: "5 / 8",
          gridRow: "26",
        }}
      >
        collaboration and commercial
      </div>
      <div
        style={{
          gridColumn: "9 / 12",
          gridRow: "26",
        }}
      >
        <p>
          if you interested in collaboration or commercial usage feel free to
          contact lisovskiy sergey
        </p>

        <p>
          <br />
          <Link to="mailto:lisovskiyserj@gmail.com" className="rounded">
            lisovskiyserj(at)gmail.com
          </Link>
        </p>
      </div>
    </div>
    <Link
      className="buy text-xl"
      to="https://www.instagram.com/patternbrutalist/"
    >
      follow
    </Link>
  </Layout>
)

export default SecondPage
